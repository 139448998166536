import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
// import Headroom from 'react-headroom'
import Logo from "../../images/Header/R_logo.svg"
import LogoSmall from "../../images/Header/small-logo.svg"
import LangDropdown from '../LangDropdown'
import Wrapper from "./style"
import { useWindowSize } from '@react-hook/window-size/throttled'
import { useScrollPosition } from "../../hooks/useScrollPosition"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

let navLinks = [
  {
    name: `Fale connosco`,
    url: `#form-contact`,
  }
]

// COMPONENT
const Header = ({ location }) => {
  const [isDarkened, setIsDarkened] = useState(false)
  const [logoSmall, setLogoSmall] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const [width, height] = useWindowSize()

  const toScroll = () => {
    let element = document.querySelector(`#form-contact`)
    let offset = -1
    let bodyRect = document.body.getBoundingClientRect().top
    let elementRect = element.getBoundingClientRect().top
    let elementPosition = elementRect - bodyRect
    let offsetPosition = elementPosition - offset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
  
  //CHANGE HEADER COLOR
  let colorDiv = '#introduction'
  let secondColorDiv = '#form-contact'

  const colorPositionStart = useRef(null), 
        colorPositionEnd = useRef(null), 
        secondColorPositionStart = useRef(null), 
        secondColorPositionEnd = useRef(null),
        thirdColorPositionStart = useRef(null), 
        thirdColorPositionEnd = useRef(null),
        introExists = useRef(null), 
        formExists = useRef(null),
        questionExists = useRef(null),
        headerWrapperRef = useRef(null),
        wrapperRef = useRef(null),
        introStart = useRef(null),
        introEnd = useRef(null),
        formStart = useRef(null),
        formEnd = useRef(null),
        questionStart = useRef(null),
        questionEnd = useRef(null),
        svgBig = useRef(null),
        svgSmall = useRef(null),
        timeout = useRef(null)
  
  // Lógica de trocar o logo, edge-case de trocar muito rápido
  useEffect(() => {
    svgBig.current = document.querySelector('#svg-logo-big')
    svgSmall.current = document.querySelector('#svg-logo-small')
    if(logoSmall) {
      svgBig.current.style.opacity = 0
      svgBig.current.style.pointerEvents = 'none'
      if(!isMoving) {
        setIsMoving(true)
        timeout.current = setTimeout(() => {
          svgSmall.current.style.opacity = 1
          svgSmall.current.style.pointerEvents = 'all'
          setIsMoving(false)
        }, 300)
      } else {
        clearTimeout(timeout.current)
        setIsMoving(false)
        svgSmall.current.style.opacity = 1
        svgSmall.current.style.pointerEvents = 'all'
      }
    } else {
      svgSmall.current.style.opacity = 0
      svgSmall.current.style.pointerEvents = 'none'
      if(!isMoving) {
        setIsMoving(true)
        timeout.current = setTimeout(() => {
          svgBig.current.style.opacity = 1
          svgBig.current.style.pointerEvents = 'all'
          setIsMoving(false)
        }, 300)
      } else {
        clearTimeout(timeout.current)
        setIsMoving(false)
        svgBig.current.style.opacity = 1
        svgBig.current.style.pointerEvents = 'all'
      }
    }
  }, [logoSmall]) // eslint-disable-line

  useEffect(() => {
    let colorDivX = document.querySelector(colorDiv)
    let secondColorDivX = document.querySelector(secondColorDiv)
    let thirdColorDivX = document.querySelector('#feature-1')
    let thirdColorDivXX = document.querySelector('#question')

    colorDivX && (colorPositionStart.current = colorDivX.offsetTop)
    colorDivX && (colorPositionEnd.current = colorDivX.offsetHeight)
    secondColorDivX && (secondColorPositionStart.current = secondColorDivX.offsetTop)
    secondColorDivX && (secondColorPositionEnd.current = secondColorDivX.offsetHeight)
    thirdColorDivX && (thirdColorPositionStart.current = thirdColorDivX.offsetTop)
    thirdColorDivX && (thirdColorPositionEnd.current = thirdColorDivXX.offsetHeight)

    colorDivX ? (introExists.current = true) : (introExists.current = false)
    secondColorDivX ? (formExists.current = true) : (formExists.current = false)
    thirdColorDivX ? (questionExists.current = true) : (questionExists.current = false)

    introStart.current = colorPositionStart.current;
    formStart.current = secondColorPositionStart.current;
    questionStart.current = thirdColorPositionStart.current;
    introEnd.current = (parseInt(colorPositionStart.current) + parseInt(colorPositionEnd.current));
    formEnd.current = (parseInt(secondColorPositionStart.current) + parseInt(secondColorPositionEnd.current));
    questionEnd.current = (parseInt(thirdColorPositionStart.current) + parseInt(thirdColorPositionEnd.current));
    
  }, [width, height, colorDiv, secondColorDiv])


  useEffect(() => {
    gsap.timeline({
      paused: true,
      scrollTrigger: {
        id: 'video',
        trigger: wrapperRef.current
      }
    })
    .fromTo(headerWrapperRef.current, {opacity: 0}, {opacity: 1, duration: 1})
  }, [])
  
  //GET SCROLL POSITION AND CHANGE COLOR
  useScrollPosition(
    ({ currPos }) => {
      let position = Math.abs(currPos.y);

      if(position > 250) {
          setLogoSmall(true)
      } else {
          setLogoSmall(false)
      }
      
      ((position > introStart.current && position <= introEnd.current) || (position > formStart.current && position <= formEnd.current) || (position > questionStart.current && position <= questionEnd.current)) ? setIsDarkened(true) : setIsDarkened(false);
    },
    [isDarkened]
  )
// ---

  return (
    <Wrapper isDarkened={isDarkened} location={location.pathname} id='header' ref={wrapperRef}>
      <div className="header-wrapper" ref={headerWrapperRef}>
        <div className="logo-container">
          <Link to="/pt">
            <Logo id="svg-logo-big"/>
            <LogoSmall id="svg-logo-small"/>
          </Link>
        </div>
        <nav className="nav-primary">
          {navLinks.map(x => (
            <button key={x.name} onClick={toScroll} style={{ cursor: 'pointer' }} className="nav-link hvr-overline-from-center" id={x.url}>
              <div>{x.name}</div>
            </button>
          ))}
          <nav className="nav-secondary">
            <LangDropdown
              lang = "PT"
              location={location}
            />
          </nav>
        </nav>
      </div>
    </Wrapper>
  )
}

// PROP-TYPES
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header