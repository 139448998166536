import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import styled from "styled-components"

// Components
import Header from "../components/Header/pt"
import HeaderMobile from "../components/Header/mobilePt"
import Footer from "../components/Footer"

import "./reset.css"
import "./layout.css"
import "./global.js"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitlePTQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFooterJson {
        edges {
          node {
            pt {
              text1
              text2
            }
          }
        }
      }
    }
  `)

  const breakpoints = useBreakpoint();

  return (
    <Wrapper>
      {breakpoints.md ? 
        <HeaderMobile siteTitle={data.site.siteMetadata.title} location={location} /> : 
        <Header siteTitle={data.site.siteMetadata.title} location={location} />
      }
      <main>{children}</main>
      {!(location.pathname === '/404/') && <Footer lang="pt" text1={data.allFooterJson.edges[0].node.pt.text1} text2={data.allFooterJson.edges[0].node.pt.text2}/>}
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .full-height {
    max-height: 100vh;
    overflow-x: hidden;
  }
  .full-width {
    max-width: 100%;
    overflow-y: hidden;
  }
  .white-bg {
    background-color: #ffffff;
  }
  .black-bg {
    background-color: #000000;
  }
`
